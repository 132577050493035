
import { getPhysicalCondition, savePurposePhysical } from '@/api/diary';
import { Form } from 'element-ui';
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getPhysicalCondition();
  }

  private form: any = {
    purposeMuscleWeight: 0,
    purposeFatRate: 0,
    weight: this.$route.query.weight,
    purposeWeight: this.$route.query.goalWeight,
    goalDate: this.$route.query.goalDate,
    diaryDate: '',
  }

  private loading = false;

  private rules = {
    goalDate: [
      { required: true, message: '목표 날짜를 선택하세요.', trigger: ['blur', 'change'] },
    ],
    purposeWeight: [
      { required: true, message: '목표 체중을 입력하세요.', trigger: ['blur', 'change'] },
    ],
    purposeMuscleWeight: [
      { required: true, message: '목표 골격근량을 입력하세요.', trigger: ['blur', 'change'] },
    ],
    purposeFatRate: [
      { required: true, message: '목표 체지방율을 입력하세요.', trigger: ['blur', 'change'] },
    ],
  }

  private getPhysicalCondition() {
    if (this.$route.query.diaryDate) {
      this.loading = true;
      getPhysicalCondition({ searchDate: this.$route.query.diaryDate }).then((res) => {
        // this.form = res.data;
        this.form = {
          ...this.form,
          idx: res.data.idx,
          fatRate: res.data.fatRate,
          muscleWeight: res.data.muscleWeight,
          purposeFatRate: res.data.purposeFatRate,
          purposeMuscleWeight: res.data.purposeMuscleWeight,
        };
        this.loading = false;
      });
    } else {
      this.$message.warning('잘못된 접근입니다.');
      this.$router.push({ name: 'Diary' });
    }
  }

  private handleSave() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        this.form.diaryDate = this.$route.query.diaryDate;
        savePurposePhysical(this.form).then(() => {
          this.$message.success('체성분 목표가 설정되었습니다.');
          this.$router.push({ name: 'Diary', query: { ...this.$route.query, tab: 'body' } });
        });
      }
    });
  }
}
